import React from "react";
import "./App.css";

const fuccbois = [
  "mavericksubaru",
  "just_another_st3",
  "phil_yumm",
  "gagebrumblebee",
  "phocisticks"
];

const Car = ({ username }) => (
  <img align='center' alt={username} src={process.env.PUBLIC_URL + `/cars/${username}.png`} />
)


const Insta = ({ username }) => (
  <p><a href={`https://www.instagram.com/${username}`}>@{username}<Car username={username} /></a></p>
);

const Main = () => (
  <div className="main">
    <p>{"product of the night."}</p>
  </div>
);

const Page = ({ children }) => <div className="page">{children}</div>;

const Footer = ({ children }) => <div className="footer">{children}</div>;

function App() {
  return (
    <Page>
      <Main />
      <Footer>
        {fuccbois.map(fuccboi => (
          <Insta key={fuccboi} username={fuccboi} />          
        ))}
      </Footer>
    </Page>
  );
}

export default App;
